<template>
  <!-- 整体内容 -->
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard categoryTreeTitle="供商资料" @treeNodeId="getTreeNodeId" @appendTree="appendTree" @editTree="editTree"
      @removeTree="removeTree" @treeNodeActiveName="treeNodeActiveName" :isEditBtn="true" :boxHeight="boxHeight"
      :boxInternalHeight="boxInternalHeight" :categoryTreeOptions="areaTree" :isMultipleEdits="true"
      :categoryTreeOptions2="groupTree" />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" :dropDownList="dropDownList"
              :isAdvancedSearch="false" :isBillDate="false" :isSearchInput="true" isSearchInputTitle="输入供商编码、名称" />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="供商信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :multiple="multiple" :isBatchEditorBtn="true" :isImportBtn="true"
              :isExportBtn="true" :isRecycleBtn="true" :isDeleteBtn="false" @handleExport="handleExport"
              @handleImport="openToChannel = true" @handleAdd="handleAdd" @batchEditor="batchEditor"
              @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true" />
            <div>
              <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
                @selection-change="handleSelectionChange" border v-loading="loadingTable" @row-click="handleRowClick"
                :max-height="tableHeight" :height="tableHeight">
                <el-table-column type="selection" width="55" align="center">
                </el-table-column>
                <el-table-column align="center" type="index" label="序号" width="80">
                </el-table-column>
                <el-table-column align="center" prop="partnerNo" label="供应商编码" show-overflow-tooltip width="160">
                  <template slot-scope="scope">
                    <el-link type="primary" @click="handleDetail(scope.row)" target="_blank">
                      {{ scope.row.partnerNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="partnerName" label="供应商名称" show-overflow-tooltip width="160">
                </el-table-column>

                <el-table-column label="供应商类型" align="center" prop="partnerType" width="120">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.partner_type" :value="scope.row.partnerType" />
                  </template>
                </el-table-column>
                <el-table-column prop="areaName" label="供应商区域" show-overflow-tooltip width="120" align="center">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.areaId == 0 ? '全部区域' : scope.row.areaName
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="groupName" label="供应商组别" show-overflow-tooltip width="120" align="center">
                  <template slot-scope="scope">
                    <span>{{
                      scope.row.groupId == 0 ? '全部分组' : scope.row.groupName
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="priceCaseName" label="价格方案" show-overflow-tooltip width="120" align="center" />
                <el-table-column label="结算方式" align="center" prop="payType">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.pay_type" :value="scope.row.payType" />
                  </template>
                </el-table-column>
                <el-table-column label="状态" align="center" prop="status">
                  <template slot-scope="scope">
                    <dict-tag :options="dict.type.common_status" :value="scope.row.status" />
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="invoiceDepositBank" label="开户银行" show-overflow-tooltip
                  width="120">
                </el-table-column>
                <el-table-column align="center" prop="invoiceBankName" label="开户名称" show-overflow-tooltip width="120">
                </el-table-column><el-table-column align="center" prop="invoiceBankNo" label="银行账户"
                  show-overflow-tooltip width="120">
                </el-table-column>
                <el-table-column prop="linkMan" label="联系人" show-overflow-tooltip width="120" align="center"
                  :formatter="linkMan">
                </el-table-column><el-table-column prop="linkManTel" label="联系电话" show-overflow-tooltip width="120"
                  align="center" :formatter="linkManTel">
                </el-table-column><el-table-column align="center" prop="linkAddress" label="地址" show-overflow-tooltip
                  width="120" :formatter="linkAddress">
                </el-table-column>
                <!-- <el-table-column
                  align="center"
                  prop="taxRate"
                  label="税率"
                  show-overflow-tooltip
                  width="120"
                >
                </el-table-column> -->
                <el-table-column align="center" prop="invoiceTitle" label="发票抬头" show-overflow-tooltip width="120">
                </el-table-column><el-table-column align="center" prop="invoiceLicenseNo" label="纳税人识别号"
                  show-overflow-tooltip width="120">
                </el-table-column><el-table-column align="center" prop="creditAmount" label="授信额度" show-overflow-tooltip
                  width="120">
                </el-table-column><el-table-column align="center" prop="usedAmount" label="已用额度" show-overflow-tooltip
                  width="120">
                </el-table-column>
                <el-table-column align="center" prop="usableAmount" label="剩余额度" show-overflow-tooltip width="120">
                </el-table-column>
                <el-table-column prop="remark" label="备注" show-overflow-tooltip width="120" align="center"
                  :formatter="remark">
                </el-table-column><el-table-column align="center" prop="postalCode" label="邮编" show-overflow-tooltip
                  width="120">
                </el-table-column>
                <el-table-column align="center" prop="updateBy" label="修改人" show-overflow-tooltip width="155">
                </el-table-column>
                <el-table-column align="center" prop="updateTime" label="修改时间" show-overflow-tooltip width="155">
                </el-table-column>
                <el-table-column align="center" prop="createBy" label="创建人" show-overflow-tooltip width="155">
                </el-table-column><el-table-column align="center" prop="createTime" label="创建时间" show-overflow-tooltip
                  width="155">
                </el-table-column>
              </el-table>
              <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
                @selectAllCom="selectAll" />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 批量编辑 -->
    <c-dialog title="批量修改" :width="500" :showDialog.sync="dialogVisible">
      <template #content>
        <el-form :model="batchForm">
          <el-form-item label="供应商区域" :label-width="formLabelWidth">
            <treeselect v-model="batchForm.areaId" :options="areaTree" :show-count="true" placeholder="请选择客户区域"
              :normalizer="normalizer" />
          </el-form-item>

          <el-form-item label="供应商组别" :label-width="formLabelWidth">
            <treeselect v-model="batchForm.groupId" :options="groupTree" :show-count="true" placeholder="请选择客户组别"
              :normalizer="normalizer" />
          </el-form-item>

          <el-form-item label="供应商类型" :label-width="formLabelWidth">
            <el-radio-group v-model="batchForm.partnerType">
              <el-radio v-for="dict in dict.type.partner_type" :key="dict.value"
                :label="parseInt(dict.value)">{{ dict.label }}</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="价格方案" :label-width="formLabelWidth">
            <el-select class="batch" placeholder="请选择价格方案" v-model="batchForm.priceCaseId">
              <el-option v-for="item in priceCaseList" :label="item.priceCaseName" :value="item.priceCaseId"
                :key="item.priceCaseId">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBatchForm">确 定</el-button>
      </template>
    </c-dialog>

    <!-- 用户导入对话框 -->
    <el-dialog v-dialogDrag="true" :title="upload.title" :visible.sync="upload.open" width="400px">
      <el-upload ref="upload" :limit="1" accept=".xlsx, .xls" :headers="upload.headers"
        :action="upload.url + '?updateSupport=' + upload.updateSupport" :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress" :on-success="handleFileSuccess" :auto-upload="false" drag>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip" slot="tip">
          <el-checkbox v-model="upload.updateSupport" />是否更新已经存在的用户数据
          <el-link type="info" style="font-size: 12px" @click="importTemplate">下载模板</el-link>
        </div>
        <div class="el-upload__tip" style="color: red" slot="tip">
          提示：仅允许导入“xls”或“xlsx”格式文件！
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="upload.open = false">取 消</el-button>
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 添加修改区域对话框 -->
    <c-dialog :title="areaTitle" :width="500" :showDialog.sync="areaOpen">
      <template #content>
        <el-form ref="areaForm" :model="areaForm" :rules="areaRules" label-width="80px" v-loading="areaLoading">
          <el-row>
            <el-col :span="20">
              <el-form-item label="上级区域" prop="parentId">
                <treeselect v-model="areaForm.parentId" :options="areaTree" :show-count="true" placeholder="请选择上级区域"
                  :normalizer="normalizer" @input="areaInputSelect" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="区域编码" prop="areaNo">
                <el-input v-model="areaForm.areaNo" placeholder="请输入区域编码" maxlength="11" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="区域名称" prop="areaName">
                <el-input v-model="areaForm.areaName" placeholder="请输入区域名称" maxlength="30" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <el-form-item label="备注">
                <el-input type="text" v-model="areaForm.remark" placeholder="备注长度介于 1 和 30 字符之间" maxlength="30" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
      <template #buttonList>
        <el-button type="primary" @click="areaSubmitForm">确 定</el-button>
        <el-button @click="areaCancel">取 消</el-button>
      </template>
    </c-dialog>

    <!-- 添加或修改客供资料分组对话框 -->
    <c-dialog :title="groupTitle" :width="500" :showDialog.sync="groupOpen">
      <template #content>
        <el-form ref="groupForm" :model="groupForm" :rules="groupRules" label-width="80px" v-loading="groupLoading">
          <el-form-item label="上级分组" prop="parentId">
            <treeselect v-model="groupForm.parentId" :options="groupTree" :show-count="true" placeholder="请选择上级分组"
              :normalizer="normalizer" @input="groupInputSelect" />
          </el-form-item>
          <el-form-item label="组别编码" prop="groupNo">
            <el-input v-model="groupForm.groupNo" placeholder="请输入组别编码" />
          </el-form-item>
          <el-form-item label="组别名称" prop="groupName">
            <el-input v-model="groupForm.groupName" placeholder="请输入组别名称" />
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input v-model="groupForm.remark" placeholder="请输入备注" />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button type="primary" @click="groupSubmitForm">确 定</el-button>
        <el-button @click="groupCancel">取 消</el-button>
      </template>
    </c-dialog>
    <to-channel :visible.sync="openToChannel" :downloadFile="downloadFile" @upload="uploadFile"></to-channel>
    <importError :errVisible.sync="errVisible" :errorList="errorList" />
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="getList" />
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
// 导入模板接口importTemplate
import { importTemplate } from '@/api/system/user'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {
  listSupplier,
  delSupplier,
  uploadFileRequest,
  restorePartnerSupplierInfo
} from '@/api/goods/supplier.js'
import { statusCustomer } from '@/api/goods/customer.js' //批量修改
import {
  getAreaTree,
  addArea,
  delArea,
  getAreaDetail,
  updateArea
} from '@/api/goods/area.js' //客供区域
import {
  listTree,
  getGroupDetail,
  addGroup,
  updateGroup,
  delGroup
} from '@/api/goods/group' //客供分组
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import categoryTreeCard from '@/views/components/categoryTreeCard' //选择类别
import bottomPage from '@/views/components/bottomPage' //底部分页
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import operatingButton from '@/views/components/operatingButton' //操作按钮
import { priceCaseListAPI } from '@/api/system/goodsPrice/priceCase' //商品价格方案
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import ToChannel from '@/components/dialogTemplate/toChannel/index.vue'
import importError from '@/views/components/importError' //错误信息
import Dialog2 from '@/components/Dialog' //回收站
export default {
  name: 'supplier',
  dicts: ['partner_type', 'common_status', 'pay_type'],
  components: {
    Treeselect,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    CDialog,
    ToChannel,
    importError,
    Dialog2
  },
  data() {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: listSupplier,
          restoreListApi: restorePartnerSupplierInfo,
          id: 'partnerId',
          search: [
            {
              type: 'filters',
              isRestore: true,
              model: '',
              filters: [
                { filter: 'query', label: '全部' },
                { filter: 'partnerNos', label: '供应商编码' },
                { filter: 'partnerNames', label: '供应商名称' }
              ]
            },
            {
              type: 'button',
              tip: '查询',
              btnType: 'primary',
              click: 'search',
              isRestore: true
            }
          ],
          columns: [
            {
              prop: 'partnerNo',
              label: '供应商编码',
              minWidth: 180
            },
            {
              prop: 'partnerName',
              label: '供应商名称',
              minWidth: 180
            },
            {
              prop: 'partnerTypeName',
              label: '供应商类型',
              minWidth: 100
            },
            {
              formatter: (v, row) =>
                row?.areaId == 0 ? '全部区域' : row?.areaName,
              prop: 'areaName',
              label: '供应商区域',
              minWidth: 120
            },
            {
              formatter: (v, row) =>
                row?.groupId == 0 ? '全部分组' : row?.groupName,
              prop: 'groupName',
              label: '供应商组别',
              minWidth: 120
            },
            {
              prop: 'priceCaseName',
              label: '价格方案',
              minWidth: 120
            },
            {
              formatter: (v, row) =>
                row?.payType == 0
                  ? '现金'
                  : row?.payType == 1
                    ? '支票'
                    : '银行账户',
              prop: 'payType',
              label: '结算方式',
              minWidth: 120
            },
            {
              formatter: (v, row) =>
                row?.payType == 0
                  ? '正常'
                  : row?.payType == 1
                    ? '停用'
                    : '删除',
              prop: 'status',
              label: '状态',
              minWidth: 120
            },
            {
              prop: 'remark',
              label: '备注',
              minWidth: 120
            },
            {
              prop: 'updateBy',
              label: '修改人',
              minWidth: 120
            },
            {
              prop: 'updateTime',
              label: '修改时间',
              minWidth: 155
            },
            {
              prop: 'createBy',
              label: '创建人',
              minWidth: 155
            },
            {
              prop: 'createTime',
              label: '创建时间',
              minWidth: 155
            }
          ]
        }
      },
      //错误信息
      errVisible: false,
      //错误信息
      errorList: [],
      downloadFile: {
        // 下载模板
        fileName: '客户导入模板.xlsx', // 文件名
        downloadUrl:
          'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-06-24/供商资料导入.xlsx' // 下载地址
      },
      openToChannel: false, // 打开导入弹框
      priceCaseList: [], //商品价格
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'partnerNos', name: '供应商编码' },
        { parameter: 'partnerNames', name: '供应商名称' }
      ],
      areaTitle: '新增客供区域', //区域树弹框标题
      groupTitle: '新增客供资料分组', //分组树弹框标题
      areaOpen: false, //区域树弹框
      groupOpen: false, //分组树弹框
      areaForm: {}, //区域弹框表单
      groupForm: {}, //分组弹框表单
      // 区域表单校验
      areaRules: {
        parentId: [
          {
            required: true,
            message: '上级区域不能为空',
            trigger: 'input'
          }
        ],
        areaNo: [
          {
            required: true,
            message: '区域编码不能为空',
            trigger: ['blur', 'change']
          }
        ],
        areaName: [
          {
            required: true,
            message: '区域名称不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      // 分组表单校验
      groupRules: {
        groupNo: [
          { required: true, message: '组别编码不能为空', trigger: 'blur' },
          { pattern: /^[0-9]{1,20}$/, message: '请输入1 ~ 20个数值类型的字符' }
        ],
        groupName: [
          { required: true, message: '组别名称不能为空', trigger: 'blur' },
          { pattern: /^.{1,40}$/, message: '请输入1 ~ 40个字符' }
        ],
        parentId: [
          { required: true, message: '父级ID不能为空', trigger: 'input' }
        ]
      },
      areaLoading: false, //区域
      groupLoading: false, //分组
      loading: false, //全局加载
      loadingTable: false, //表格加载
      single: true, //编辑和的禁用
      multiple: true, //删除和批量修改
      total: 0, //分页总条数
      areaTree: [], //区域tree
      groupTree: [], //分组tree
      name: [], //选中数组的名称
      ids: [], // 选中数组
      dialogVisible: false, //批量编辑对话框
      nodeId: null, //点击的节点id
      labelTab: '区域',
      batchForm: {
        partnerType: 0
      }, //批量对话框的表单
      formLabelWidth: '100px',
      defaultProps: {
        //tree结构
        children: 'children',
        label: 'label'
      },
      //   右边表格
      tableData: [
        {
          partnerName: null, //客供名称
          partnerNo: null, //客供编码
          partnerType: null, //客供类型（0 客供 1 客户 2 供应商 3 伙伴 ）
          payType: null, //付款方式（0 现金 1 支票 2 银行帐户）
          address: null, //地址
          taxRate: null, //税率
          invoiceDepositBank: null, //开户银行
          invoiceBankName: null, //银行账户名称
          invoiceLicenseNo: null, //发票纳税人识别号
          creditAmount: null, //信用额度
          usedAmount: null, //已用额度
          remark: null, //备注
          postalCode: null, //邮编
          createBy: null, //创建人
          createTime: null, //创建时间
          updateBy: null, //修改人
          updateTime: null, //修改时间
          consigneeMan: null, //联系人
          consigneeManTel: null, //联系电话
          areaName: null, //客户区域
          groupName: null //组别名称
        }
      ],
      //表格选中内容
      multipleSelection: [],
      partnerId: null, //表格选中id
      //分页查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        areaId: null, // 片区id
        groupId: null, //组别ID
        paramName: null, //名称、编码
        regionId: null //区域id
      },

      // 用户导入参数
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: '',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: 'Bearer ' + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + '/system/user/importData'
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140
    },
    boxHeight() {
      return this.vivwH - 10
    },
    boxInternalHeight() {
      return this.vivwH - 83
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  async created() {
    this.getRender()
    const res = await priceCaseListAPI({ pageNum: 1, pageSize: 100000 })
    this.priceCaseList = res.rows
  },
  methods: {
    uploadFile(fromData) {
      uploadFileRequest(fromData)
        .then(res => {
          this.$message.success('上传成功')
          this.openToChannel = false
          this.getList()
        })
        .catch(error => {
          this.errorList = error.data.errorList.map(item => {
            return { errorInfo: item }
          })
          this.errVisible = true
        })
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //新增
    handleAdd() {
      this.$router.push('/partner/supplier/newSupplier')
    },
    //类别名称
    treeNodeActiveName(value) {
      this.labelTab = value
    },
    //点击类别树
    getTreeNodeId(value) {
      this.queryParams = {
        pageNum: 1,
        pageSize: 15,
        areaId: null, // 片区id
        groupId: null, //组别ID
        paramName: null //名称、编码
      }
      if (this.labelTab == '区域') {
        this.queryParams.areaId = value
      } else {
        this.queryParams.groupId = value
      }
      this.getList()
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await listSupplier({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value)
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    },
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row)
    },
    //  修改\点击编码
    handleDetail(row) {
      //传递参数
      this.$router.push({
        path: '/partner/supplier/newSupplier',
        query: {
          id: row.partnerId,
          type: 'Update'
        }
      })
    },

    // 区域下拉框校验方法
    areaInputSelect() {
      this.$refs.areaForm.validateField('parentId')
    },
    // 分组下拉框校验方法
    groupInputSelect() {
      this.$refs.groupForm.validateField('parentId')
    },

    //新增Tree用户组
    appendTree(data) {
      this.labelTab = data.activeName
      if (this.labelTab == '区域') {
        this.areaForm = {
          areaName: null,
          parentId: data.id,
          areaNo: null,
          remark: null
        }
        this.resetForm('areaForm')
        this.areaTitle = '新增客供区域'
        this.areaOpen = true
      } else {
        this.groupForm = {
          parentId: data.id,
          groupId: null,
          groupNo: null,
          groupName: null,
          remark: null
        }
        this.groupTitle = '新增客供分组'
        this.resetForm('groupForm')
        this.groupOpen = true
      }
    },
    //编辑Tree用户组
    async editTree(data) {
      this.labelTab = data.activeName
      if (this.labelTab == '区域') {
        this.areaTitle = '编辑客供区域'
        this.areaOpen = true
        this.areaLoading = true
        this.areaForm = {
          areaName: null,
          parentId: data.id,
          areaNo: null,
          remark: null
        }
        this.resetForm('areaForm')
        const res1 = await getAreaTree() //获取树数据
        this.areaTree = res1.data //赋值给tree
        const areaId = data.id
        getAreaDetail(areaId).then(response => {
          this.areaForm = response.data
          this.areaLoading = false
        })
      } else {
        this.groupTitle = '编辑客供分组'
        this.groupOpen = true
        this.groupLoading = true
        this.groupForm = {
          parentId: data.id,
          groupId: null,
          groupNo: null,
          groupName: null,
          remark: null
        }
        this.resetForm('groupForm')
        const groupId = data.id
        getGroupDetail(groupId).then(response => {
          this.groupForm = response.data
          this.groupLoading = false
        })
      }
    },

    //删除Tree用户组
    removeTree({ node, data }) {
      this.labelTab = data.activeName
      if (this.labelTab == '区域') {
        this.$confirm(`确定删除(${data.label})供商区域,是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          if (node.data.children) {
            this.$message({
              howClose: true,
              message: '请先删除子级供商区域',
              type: 'warning'
            })
            return
          }
          var Ids = [data.id]
          delArea(Ids).then(response => {
            this.$modal.msgSuccess('删除成功')
            this.getRender()
          })
        })
      } else {
        this.$confirm(`确定删除(${data.label})供商分组,是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
          if (node.data.children) {
            this.$message({
              howClose: true,
              message: '请先删除子级供商分组',
              type: 'warning'
            })
            return
          }
          var Ids = [data.id]
          delGroup(Ids).then(response => {
            this.$modal.msgSuccess('删除成功')
            this.getRender()
          })
        })
      }
    },
    //区域表单确认按钮
    areaSubmitForm() {
      this.$refs['areaForm'].validate(valid => {
        if (valid) {
          if (this.areaForm.areaId == this.areaForm.parentId) {
            this.$message.error('父类不能和子类相同')
            return
          }
          if (this.areaForm.areaId != undefined) {
            updateArea(this.areaForm).then(async response => {
              this.$modal.msgSuccess('修改客供区域成功')
              this.areaOpen = false
              const res1 = await getAreaTree() //获取树数据
              this.areaTree = res1.data //赋值给tree
              this.getList()
            })
          } else {
            addArea(this.areaForm).then(async response => {
              this.$modal.msgSuccess('新增客供区域成功')
              this.areaOpen = false
              const res1 = await getAreaTree() //获取树数据
              this.areaTree = res1.data //赋值给tree
              this.getList()
            })
          }
        }
      })
    },
    //区域表单取消按钮
    areaCancel() {
      this.areaForm = {
        areaName: null,
        parentId: null,
        areaNo: null,
        remark: null
      }
      this.resetForm('areaForm')
      this.areaOpen = false
    },
    //分组表单确认按钮
    groupSubmitForm() {
      this.$refs['groupForm'].validate(valid => {
        if (valid) {
          if (this.groupForm.groupId == this.groupForm.parentId) {
            this.$message.error('父类不能和子类相同')
            return
          }
          if (this.groupForm.groupId != undefined) {
            updateGroup(this.groupForm).then(async response => {
              this.$modal.msgSuccess('修改客供区域成功')
              this.groupOpen = false
              const res2 = await listTree()
              this.groupTree = res2.data
            })
          } else {
            addGroup(this.groupForm).then(async response => {
              this.$modal.msgSuccess('新增客供区域成功')
              this.groupOpen = false
              const res2 = await listTree()
              this.groupTree = res2.data
            })
          }
        }
      })
    },
    //分组表单取消按钮
    groupCancel() {
      this.groupForm = {
        parentId: null,
        groupId: null,
        groupNo: null,
        groupName: null,
        remark: null
      }
      this.resetForm('groupForm')
      this.groupOpen = false
    },
    //联系人
    linkMan(row) {
      if (row.linkmanList && row.linkmanList.length > 0) {
        return row.linkmanList[0].linkMan
      } else {
        return ''
      }
    },
    //联系电话
    linkManTel(row) {
      if (row.linkmanList && row.linkmanList.length > 0) {
        return row.linkmanList[0].linkManTel
      } else {
        return ''
      }
    },
    //联系地址
    linkAddress(row) {
      if (row.linkmanList && row.linkmanList.length > 0) {
        return row.linkmanList[0].linkAddress
      } else {
        return ''
      }
    },
    //备注
    remark(row) {
      if (row.linkmanList && row.linkmanList.length > 0) {
        return row.linkmanList[0].remark
      } else {
        return ''
      }
    },
    //点击批量修改按钮
    async batchEditor() {
      this.batchForm = {
        partnerType: 0
      } //批量对话框的表单
      this.dialogVisible = true
    },
    //批量修改确认按钮
    async submitBatchForm() {
      this.batchForm.partnerIds = this.ids
      const res = await statusCustomer(this.batchForm)
      this.$modal.msgSuccess('批量修改客户资料成功')
      this.dialogVisible = false
      this.getList()
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children
      }
    },

    //渲染事件
    async getRender() {
      this.loading = true
      this.getTreeselect()
      this.getList()
      this.loading = false
    },
    //获取树
    async getTreeselect() {
      const res1 = await getAreaTree() //获取树数据
      this.areaTree = res1.data //赋值给tree
      const res2 = await listTree()
      this.groupTree = res2.data
    },
    /** 查询分页列表 */
    getList() {
      this.loadingTable = true
      listSupplier(this.queryParams).then(response => {
        this.tableData = response.rows
        this.total = response.total
        this.loadingTable = false
      })
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.partnerId)
      this.name = selection.map(item => item.partnerName)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },

    /** 删除按钮操作 */
    handleDelete() {
      this.$confirm(`确定删除所选的<span style="color: #1890ff;"> ${this.ids.length} </span>个供商资料吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning',
      })
        .then(() => {
          console.log(this.ids)
          return delSupplier(this.ids)
        })
        .then(() => {
          this.getList()
          this.$modal.msgSuccess('删除成功')
        })
        .catch(() => { })
    },
    /** 导入按钮操作 */
    handleImport() {
      this.upload.title = '用户导入'
      this.upload.open = true
    },
    /** 下载模板操作 */
    importTemplate() {
      importTemplate().then(response => {
        this.download(response.msg)
      })
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        '/api/system/partner/supplier/exportSupplierInfo',
        {
          ...this.queryParams
        },
        `供应商资料导出.xlsx`
      )
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false
      this.upload.isUploading = false
      this.$refs.upload.clearFiles()
      this.$alert(response.msg, '导入结果', { dangerouslyUseHTMLString: true })
      this.getList()
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
        //筛选条件
        .searchCriteria {
          padding-top: 10px;
          .selectFrameUp {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}

::v-deep .vue-treeselect__control {
  width: 280px;
} //treeselect
::v-deep .vue-treeselect__menu-container {
  width: 280px;
} //treeselect
.batch {
  width: 300px;
}
//双击表格高度
</style>
